import React, { Component, Suspense } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom'
import Pass from './pages/Pass/Pass'

import LocalizationProvider from './LocalizationProvider';

import './App.scss'

Object.defineProperties(Array.prototype, {
  first: { get() { return this[0]; }},
  last:  { get() { return this[this.length - 1]; }}
});

export default class App extends Component {

  constructor(props) {
    super(props)
    this.state = {}
  }

  async componentDidMount() {
    // console.log(getSession())
  }

  render() {
    return (
      <Suspense>
      <LocalizationProvider>
      <Router>
        <Switch>
          <Route path='/v/:id'>
            <Pass />
          </Route>
          <Route path='/'>
            <Pass />
          </Route>

        </Switch>
      </Router>
      </LocalizationProvider>
      </Suspense>
    )
  }
}