
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// the translations
// (tip: move them in separate JSON files and import them)
const resources = {
  en: {
    translation: {
      "page" : {
				"ui" : {
					"passNotFound" : "Pass not found",
          "passNotFoundContact" : "Please contact us and we can assist with finding your digital boarding pass."
        }
      }
    }
  }
};
i18n
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    lng: "en",
    fallbackLng: 'en',
    debug: true,
    returnObjects: true,
    interpolation: {
      escapeValue: false
    }
  });
export default i18n;