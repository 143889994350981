import React, { Component, useState, useEffect, useContext } from 'react';
import { Link, withRouter } from 'react-router-dom';

import Skeleton from 'react-loading-skeleton';
import { QRCode, useQRCode } from 'react-qrcode';

import LocalizationContext from '../../LocalizationContext';

import { Dropdown, ListGroup, DropdownButton, ButtonGroup, Button, Modal } from 'react-bootstrap';

import axios from 'axios';

import dayjs from 'dayjs';

import queryString from 'query-string';

import { useForm, Controller } from 'react-hook-form';

import { searchPass, emailPass, smsPass } from '../../utils';

import { isBrowser, isIOS, isAndroid, isMobile, isFirefox, isEdge } from 'react-device-detect';

import styles from './Pass.module.scss';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'

//import { ReactComponent as APPLEWALLET } from '../../assets/images/Apple_Wallet.svg';
import { ReactComponent as LoaderPulse } from '../../assets/images/loading.svg';

function successHandler(e) {
	console.log('success:', e);
}
function failureHandler(e) {
	console.log('failure:', e);
}

const serveDownload = (fileName, path, passType) => {
	if (passType === 'application/pdf') {
		const link = document.createElement('a');
		link.href = path;
		link.target = '_blank';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		return true;
	}

	let rep;
	axios({
		url: path,
		method: 'GET',
		responseType: 'blob' // important
	}).then((response) => {
		//window.location.href = URL.createObjectURL(new Blob([response.data],{type: "application/vnd.apple.pkpass"}));

		const reader = new FileReader();
		const out = new Blob([ response.data ], { type: passType });
		reader.onload = function(e) {
			window.location.href = reader.result;
		};
		reader.addEventListener('error', () => {
			alert('error');
		});
		reader.readAsDataURL(out);
	});
};

const GooglePayTag = (props) => {
	useEffect(() => {
		const script = document.createElement('script');
		script.onload = function(e) {
			window.gapi.savetoandroidpay.render('googlePayPassHolder', {
				jwt: props.jwt,
				onsuccess: successHandler,
				onfailure: failureHandler,
				size: 'matchparent',
				textsize: 'large',
				theme: 'light'
			});
		};
		script.async = true;
		script.src = 'https://apis.google.com/js/platform.js';
		document.body.appendChild(script);
	}, []);
	return <div id="googlePayPassHolder" />;
};


const BoardingCard = (props) => {
	const [ activeItemIndex, setActiveItemIndex ] = useState(0);
	const [ showTerms, setShowTerms ] = useState(false);
	const { t, i18n } = React.useContext(LocalizationContext);
	const { bp, boardingPassData, index } = props;
	return (
		<div id={`pass-${index}`} className={`${styles.child} col`}>
			<div
				className={`${styles.card} py-3 shadow`}
				style={{
					backgroundColor: boardingPassData.passStyles.backgroundColor,
					color: boardingPassData.passStyles.labelColor
				}}
			>
				<div className="row px-3 mb-2">
					<div className="col-6">
						{/* <img className={`${styles.logo} img-fluid`} src={boardingPassData.logo} /> */}
						{<img className={`${styles.logo} img-fluid`} src={boardingPassData.logo} /> || (
							<Skeleton count={2} width={150} />
						)}
					</div>
					<div className="col-6 d-flex justify-content-between align-items-end">
						{bp.boardingPass.headerFields.map((f, index) => (
							<div key={index} className="">
								<div>
									<small className="d-block text-uppercase">{t([ `${f.label}` ])}</small>
									<span className="fs-12 d-inline-block text-nowrap overflow-hidden">{f.value}</span>
								</div>
							</div>
						))}
					</div>
				</div>
				<div className="col-12 mt-2 d-flex justify-content-between align-content-center">
					<div>
						<small className="d-block text-uppercase">
							{bp.boardingPass.primaryFields.find((p) => p.key === 'origin').label}
						</small>
						<h1 className="">{bp.boardingPass.primaryFields.find((p) => p.key === 'origin').value}</h1>
					</div>
					<div className={`${styles.plane} align-self-center iasi ic-hide ic-plane-af`}>
						<i>✈️</i>
					</div>
					<div>
						<small className="d-block text-right text-uppercase">
							{bp.boardingPass.primaryFields.find((p) => p.key === 'destination').label}
						</small>
						<h1 className="">{bp.boardingPass.primaryFields.find((p) => p.key === 'destination').value}</h1>
					</div>
				</div>

				<div className="col-12 mt-3 d-flex justify-content-between">
					{bp.boardingPass.auxiliaryFields.map((f, index) => (
						<div key={index} className="">
							<div>
								<small className="d-block text-uppercase">{t([ `${f.label}` ])}</small>
								{f.value}
								{/* WE MAY NEED TO EXPLORE THIS SOLUTION IN THE FUTURE */}
								{/* {f.meta && ( <span>{f.meta.value}</span> )} */}
								{/* {!f.meta && ( <span>{f.value}</span> )} */}
							</div>
						</div>
					))}
				</div>

				<div className="col-12 mt-3 d-flex justify-content-between">
					{bp.boardingPass.secondaryFields.map((f, index) => (
						<div key={index} className="">
							<div>
								<small className="d-block text-uppercase">{t([ `${f.label}` ])}</small>
								{f.value}
							</div>
						</div>
					))}
				</div>
				<div className="mt-5 col-8 mx-auto d-flex justify-content-center">
					{/* <img className={`rounded img-fluid`} src={dataUrl} /> */}

					{<QRCode className={`rounded`} width="200" value={bp.barcode.message} /> || (
						<Skeleton count={1} width={200} height={200} />
					)}
					<div
						onClick={(e) => {
							setShowTerms(!showTerms);
						}}
						className={`pointer bg-white text-dark text-center terms-icon iasi ${showTerms
							? `ic-rotate-90`
							: ``} ic-ellipsis-h-af fs-24 ic-hide rounded-circle border border-medium`}
					>
						<i>...</i>
					</div>
				</div>
				{showTerms && (
				<ListGroup className="animateScaleIn mt-4 px-3 rounded">
					{bp.boardingPass.backFields.map((f, index) => (
							<ListGroup.Item key={index} className="text-dark">
								<React.Fragment>
									<small className="pass-label text-uppercase d-block text-medium">{t([ `${f.label}` ])}</small>
									{f.value}
								</React.Fragment>
							</ListGroup.Item>
					))}
				</ListGroup>
			)}
			</div>

			

			{((isIOS && (!isFirefox && !isEdge)) || isBrowser) && (
				<div
					className="col-8 mt-3 mx-auto pointer"
					onClick={() =>
						serveDownload(
							bp.serialNumber,
							boardingPassData.iOSPassCaches[index].Location,
							'application/vnd.apple.pkpass'
						)}
				>
					{/* <a href={boardingPassData.iOSPassCaches[index].Location}> */}
					{/* <APPLEWALLET className="pointer" /> */}
					<img className="pointer img-fluid w-100" src={`/images/localized/Add_to_Apple_Wallet_rgb_${i18n.language.toUpperCase()}.svg`} />
					{/* </a> */}
				</div>
			)}

			{isIOS &&
			(isFirefox || isEdge) && (
				<div className="col-8 mt-3 mx-auto pointer">
					<h5 className="text-center w-100">
					{t('page.ui.nonWebkitWarning')}
					</h5>
				</div>
			)}
		</div>
	);
};

const BoardingPassView = (props) => {
	const { t, i18n } = React.useContext(LocalizationContext);
	const boardingPassData = props.bpData;

	return (
		<React.Fragment>
			{boardingPassData.iOSPassTemplates.map((bp, index) => (
				<BoardingCard key={index} bp={bp} boardingPassData={boardingPassData} index={index} />
			))}
		</React.Fragment>
	);
};

const ViewOnMobileQRCode = (props) => {
	const mobileUrl = useQRCode({ value: window.location.href, width: '500', margin: 0 });
	return <img className={`rounded ${styles.barcode} img-fluid`} src={mobileUrl} />;
};

const LanguageSwitcher = (props) => {
	const { t, i18n } = React.useContext(LocalizationContext);
	if(Object.keys(i18n.store.data).length < 2) return false;
	return (
		<header className="navbar navbar-light bg-white">
			<a className="navbar-brand" href={props.url}>
    		<img src={props.logo} width="80" className="d-inline-block align-top" alt="" loading="lazy" />
  		</a>
			<div className="select ml-auto">
				<Dropdown>
					<Dropdown.Toggle variant="white" id="dropdown-basic">
						{i18n.language.toUpperCase()} <i className="iasi ic-hide ic-globe-americas-bf" />
					</Dropdown.Toggle>

					<Dropdown.Menu className="list-group p-0 border-0">
						{Object.keys(i18n.store.data).map((keyVal, index) => (
							<Dropdown.Item
								className="list-group-item d-flex justify-content-between"
								key={`lang-option-a-${index}`}
								onClick={(e) => i18n.changeLanguage(keyVal)}
							>
								<span>{i18n.store.data[keyVal].translation.language}</span>
								<span className="font-weight-bold">{keyVal.toUpperCase()}</span>
							</Dropdown.Item>
						))}
					</Dropdown.Menu>
				</Dropdown>
			</div>
		</header>
	);
}

const EmailForm = (props) => {
	const { register, handleSubmit, errors } = useForm();
	const [sending, setSending] = useState(false);
	const [emailSent, setEmailSent] = useState(false);
	const [emailSuccess, setEmailSuccess] = useState(true);
	const { t, i18n } = React.useContext(LocalizationContext);
	
  const onSubmit = async(data) => {
		setSending(true);
		const emailData = await emailPass(props.id,data.email,props.lang);
		setEmailSent(true);

		if(!emailData){
			setEmailSuccess(false);
		}
		/* console.log(emailData); */
	}
	 
  return (
		<>
		{!emailSent && (<form onSubmit={handleSubmit(onSubmit)}>  
		<div className="form-group">
			<label htmlFor="emailControl">{t('page.ui.email.label')}</label>
			<input 
			id="emailControl" 
			type="email" 
			placeholder={t('page.ui.email.placeholder')} 
			name="email"
			aria-describedby="validationServer03Feedback"
			className={`form-control ${(errors.email && errors.email.message) ? 'is-invalid':''}`}
			ref={register({
				required: t('page.ui.email.required'),
				pattern: {
					value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
					message: t('page.ui.email.message')
				}
			})}
		/>
		</div>

			<div id="validationServer03Feedback" className={`my-3 invalid-feedback ${errors.email ? 'd-block':''}`}>
        {errors.email && errors.email.message}
      </div>

      <input className="btn btn-dark" type="submit" disabled={sending} value={t('page.ui.email.submit')} />
			{sending && (
				<div className="loadingSpinner">
					<LoaderPulse />
				</div>
			)}
    </form>)}
		{emailSent && (
			<>
			{emailSuccess && (<div className={'alert alert-success'}>{t('page.ui.email.confirmation')}</div>)}
			{!emailSuccess && (<div className={'alert alert-danger'}>{t('page.ui.email.error')}</div>)}
			</>
		)}
		</>
  );
}

const EmailModal = (props) => {
	const [modalShow, setEmailModalShow] = React.useState(false);
	const { t, i18n } = React.useContext(LocalizationContext);

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className={`d-flex justify-content-between`}>
        <h4>{t('page.ui.email.title')}</h4>
				<Button variant="white" onClick={props.onHide} className={'border-0 iasi ic-hide ic-times-af'}><i>×</i></Button>
      </Modal.Header>
      <Modal.Body>
				<EmailForm id={props.id} lang={i18n.language} />
      </Modal.Body>
    </Modal>
  );
}

const phoneRegExpHtml = `[+0-9-() ]+`;

const SmsForm = (props) => {
	const { register, handleSubmit, errors, control } = useForm();
	const [sending, setSending] = useState(false);
	const [smsSent, setSmsSent] = useState(false);
	const [phone, setPhone] = useState(null);
	const [smsSuccess, setSmsSuccess] = useState(true);
	const { t, i18n } = React.useContext(LocalizationContext);

	// IF we want to use react hook form to manage the phone number we can do this (effort not currently justified): 
	// https://youssef-samih97.medium.com/how-to-use-react-phone-input-2-with-react-hook-form-bcad45e95f2d
	// https://github.com/youssefSamih/telInputExample/blob/main/telInput

	
  const onSubmit = async(data) => {
		setSending(true);
		//await new Promise(resolve => setTimeout(resolve, 1000));
		//const smsData = false;
		const smsData = await smsPass(props.id,phone,props.lang);
		setSmsSent(true);

		if(!smsData){
			setSmsSuccess(false);
		}
	}

	 
  return (
		<>
		{!smsSent && (<form onSubmit={handleSubmit(onSubmit)}>  
		<div className="form-group">
		<label htmlFor="smsControl">{t('page.ui.sms.label')}</label>
	
		<Controller
        name={"sms"}
        control={control}
				rules={{ 
					required: t('page.ui.sms.required'),
					minLength: {
						value: 10,
						message: t('page.ui.sms.required')
					}
				}}
        defaultValue=""
				onChange={(v) => (console.log("hello"))}
        render={({onChange}) => {
          return (
            <PhoneInput
              inputProps={{
                name: "sms",
								id: "smsControl",
								className: `form-control ${(errors.sms && errors.sms.message) ? 'is-invalid':''}`
              }}
              country={props.settings.sms.whitelistCountries[1] || props.settings.sms.whitelistCountries[0] || 1}
							onlyCountries={props.settings.sms.whitelistCountries}
							enableSearch={true}
							searchPlaceholder={t('page.ui.sms.search.label')}
							searchNotFound={t('page.ui.sms.search.notfound')}
              onChange={(v) => {
								setPhone(v)
								onChange(v)
							}}
            />
          );
        }}
      />
		</div>

			<div id="validationServer04Feedback" className={`my-3 invalid-feedback ${errors.sms ? 'd-block':''}`}>
        {errors.sms && errors.sms.message}
      </div>

      <input className="btn btn-dark" type="submit" disabled={sending} value={t('page.ui.sms.submit')} />
			{sending && (
				<div className="loadingSpinner">
					<LoaderPulse />
				</div>
			)}
    </form>)}
		{smsSent && (
			<>
			{smsSuccess && (<div className={'alert alert-success'}>{t('page.ui.sms.confirmation')}</div>)}
			{!smsSuccess && (<div className={'alert alert-danger'}>{t('page.ui.sms.error')}</div>)}
			</>
		)}
		</>
  );
}

const SmsModal = (props) => {
	const [modalShow, setEmailModalShow] = React.useState(false);
	const { t, i18n } = React.useContext(LocalizationContext);
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className={`d-flex justify-content-between`}>
        <h4>{t('page.ui.sms.title')}</h4>
				<Button variant="white" onClick={props.onHide} className={'border-0 iasi ic-hide ic-times-af'}><i>×</i></Button>
      </Modal.Header>
      <Modal.Body>
				<SmsForm id={props.id} lang={i18n.language} settings={props.settings} />
      </Modal.Body>
    </Modal>
  );
}

class Pass extends Component {
	static contextType = LocalizationContext;

	constructor(props) {
		super(props);
		this.state = {
			passData: null,
			airlineSettings: null,
			isLoading: true,
			modalShowEmail: false,
			modalShowSms: false,
		};
	}

	async componentDidMount() {
		const { t, i18n } = this.context;

		if (!this.props.match.params.id) {
			this.setState({ passData: { error: true }, isLoading: false });
			return;
		}
		try {
			const data = await searchPass(this.props.match.params.id);
			
			if (data.i18n) {
				// change the page to the language specified on the pass, if a translations is available for it.
				if(data.pass.languageCode && data.i18n[data.pass.languageCode]) i18n.changeLanguage(data.pass.languageCode)
				
				// if lang is set via queryString try to override it:
				const qp = queryString.parse(this.props.location.search)
				/* console.log(qp) */
				if(qp && qp.lang && data.i18n[qp.lang]){
					i18n.changeLanguage(qp.lang)
					const location = Object.assign({}, this.props.location);
					location.search = "";
					this.props.history.replace(location);
				} 

				Object.keys(data.i18n).forEach((key) => {
					i18n.addResourceBundle(`${key}`, 'translation', data.i18n[key], true, true);
				});
			}
			this.setState({ passData: data.pass, airlineSettings: data.settings, isLoading: false });
		} catch (e) {
			this.setState({ passData: { error: true }, isLoading: false });
		}
	}

	setEmailModalShow(action) {
		this.setState({modalShowEmail: action});
	}
	setSmsModalShow(action) {
		this.setState({modalShowSms: action});
	}

	render() {
		const { t, i18n } = this.context;
		return (
			<React.Fragment>
				
				{(this.state.passData &&
				!this.state.passData.error && (
					<React.Fragment>
					<LanguageSwitcher logo={this.state.passData.logo} url={this.state.airlineSettings.airlineUrl} />
					<div
						className={`animateFadeIn container-fluid pt-3 ${isMobile &&
						this.state.passData.iOSPassTemplates.length > 1
							? 'p-0'
							: ''}`}
					>
						<div className={`container ${isMobile ? 'p-0' : ''} pb-5`}>
							<div className={`row`}>
								<div
									className={`col-12 mx-auto d-flex justify-content-start justify-content-sm-center`}
								>
									<div
										className={`${styles.passes} ${isMobile ? styles.mobile : ''} pb-3`}
										data-columns={this.state.passData.iOSPassTemplates.length}
									>
										<BoardingPassView bpData={this.state.passData} />
									</div>
								</div>
							</div>

							{(isAndroid || isBrowser) && (
								<div className={`row mt-5`}>
									<div className={`col-12 col-sm-6 col-md-4 mx-auto`}>
										{/* <h5 className="text-center w-100">Add on GooglePay wallet</h5> */}
										<GooglePayTag jwt={this.state.passData.googlePayPassJWT} />
									</div>
								</div>
							)}

							<div className={`row mt-5`}>
								<div className={`col-auto d-flex justify-content-between mx-auto`}>
								<EmailModal
										id={this.props.match.params.id}
        						show={this.state.modalShowEmail}
        						onHide={() => this.setEmailModalShow(false)}
								/>
								<SmsModal
										id={this.props.match.params.id}
        						show={this.state.modalShowSms}
										settings={this.state.airlineSettings}
        						onHide={() => this.setSmsModalShow(false)}
								/>
								<ButtonGroup vertical={isMobile} size="lg" className={`mx-auto`}>
								{((this.state.airlineSettings.pageActions && this.state.airlineSettings.pageActions.downloadEnabled)|| this.state.airlineSettings.pageActions === null) && (<Button variant="outline-dark" className={`d-flex justify-content-between iasi ic-flex ic-sb ic-file-pdf-af`} onClick={() =>
												serveDownload(
													'',
													this.state.passData.pdfPassCache.Location,
													'application/pdf'
												)}><span className="d-block mr-3">{t('page.ui.downloadPdf')}</span></Button>)}
									
									{((this.state.airlineSettings.pageActions && this.state.airlineSettings.pageActions.emailEnabled)|| this.state.airlineSettings.pageActions === null) && (<Button variant="outline-dark" className={`d-flex justify-content-between iasi ic-flex ic-sb ic-envelope-af`} 
									onClick={() => this.setEmailModalShow(true)}>
											<span className="d-block mr-3">{t('page.ui.email.title')}</span>
										</Button>)}
									
									{this.state.airlineSettings.pageActions && this.state.airlineSettings.pageActions.smsEnabled && (<Button variant="outline-dark" className={`d-flex justify-content-between iasi ic-flex ic-sb ic-envelope-af`} 
									onClick={() => this.setSmsModalShow(true)}>
											<span className="d-block mr-3">{t('page.ui.sms.title')}</span>
										</Button>)}

									{/* <DropdownButton variant="outline-dark" as={ButtonGroup} title={t('page.ui.email.title')} id="bg-nested-dropdown">
										<EmailForm id={this.props.match.params.id} lang={i18n.language} />
									</DropdownButton> */}
								</ButtonGroup>
									</div>
								</div>
							
							{!isMobile && (
								<div className={`row mt-5 mb-5 pb-5`}>
									<div className={`col-12 col-sm-4 col-md-4 mx-auto`}>
										<h6 className="text-center w-100 mb-3">
											{t('page.ui.scanCode')}
										</h6>
										<ViewOnMobileQRCode passId={this.state.passData.passId} />
									</div>
								</div>
							)}
						</div>
					</div>
					</React.Fragment>
				)) ||
					(this.state.isLoading && (
						<div className={`animateFadeIn container-fluid pt-3 p-0`}>
							<div className={`container ${isMobile ? 'p-0' : ''}`}>
								<div className={`row`}>
									<div
										className={`col-10 mx-auto d-flex justify-content-start justify-content-sm-center`}
									>
										<div className={`${styles.passes} ${isMobile ? styles.mobile : ''}`}>
											<Skeleton count={1} height={450} className={`${styles.skeleton}`} />
										</div>
									</div>
								</div>
							</div>
						</div>
					))}

				{this.state.passData &&
				this.state.passData.error && (
					<div className="animateFadeIn container h-100">
						<div className="row h-100 justify-content-center align-items-center">
							<div>
								<h2 className="text-center w-100">{t('page.ui.passNotFound')}</h2>
								<p className="d-block p-3 text-center">
									{t('page.ui.passNotFoundContact')}
								</p>
							</div>
						</div>
					</div>
				)}
			</React.Fragment>
		);
	}
}

export default withRouter(Pass);
