/**
 * Global Config
 */

const config = {}

// Domains
config.domains = {}

/**
 * API Domain
 * Add the domain from your serverless express.js back-end here.
 * This will enable your front-end to communicate with your back-end.
 * (e.g. 'https://api.mydomain.com' or 'https://091jafsl10.execute-api.us-east-1.amazonaws.com')
 */
//config.domains.api = 'https://74u917sclk.execute-api.eu-central-1.amazonaws.com'

if(window._apiUrl.includes("${")){
  window._apiUrl = process.env.REACT_APP_API_URL
}

config.domains.api = window._apiUrl;

export default config